import React from "react";
import { Link } from "gatsby";

import chpIcon from "../img/pricing/chp-icon.svg";
import customBullet from "../img/pricing/custom-bullet.svg";
import chpBg from "../img/pricing/chp-bg.svg";

import orangeBg from "../img/pricing/orange-bg.svg";
import blueBg from "../img/pricing/blue-bg.svg";
import purpleBg from "../img/pricing/purple-bg.svg";

const PriceBlock = (props) => {
  var bgImage, bgSize, bgPosition, bgColorOverride;

  if (props.bgImage === "orange") {
    bgImage = orangeBg;
    bgSize = "auto 100%";
    bgPosition = "left top";
  } else if (props.bgImage === "blue") {
    bgImage = blueBg;
    bgSize = "auto 100%";
    bgPosition = "left top";
  } else if (props.bgImage === "purple") {
    bgImage = purpleBg;
    bgSize = "auto 100%";
    bgPosition = "left top";
  } else if (props.bgImage === "chp") {
    bgImage = chpBg;
    bgPosition = "center top";
    bgColorOverride = "#4665B0";
  }

  return (
    <>
      <section className="container pt-4">
        <div className="mx-auto">
          <div className="flex flex-col md:flex-row shadow-2xl rounded-l-xl rounded-r-xl">
            <div
              className={`w-auto content-center md:w-1/2 p-10 ${props.bgColor} ${props.textColor} rounded-l-xl rounded-r-xl md:rounded-r-none bg-no-repeat`}
              style={{
                backgroundImage: `url(${bgImage})`,
                backgroundPosition: bgPosition,
                backgroundSize: bgSize,
                backgroundColor: bgColorOverride,
              }}
            >
              <h2 className="block pt-4 font-extrabold text-3xl  mb-4 pb-2 text-sm">
                {props.heading}
              </h2>
              <p className="block  mb-4">{props.description}</p>
              <p
                className="inline-block font-black  text-6xl md:text-7xl mb-6 pt-12 pb-0 md:pb-4"
                style={{ lineHeight: "30px" }}
              >
                {props.price} <br />
                <small className="pl-2 text-sm font-normal ">
                  {props.priceType}
                </small>
              </p>

              {props.img ? (
                <img
                  className="inline-block ml-10 -mt-10"
                  src={chpIcon}
                  role="presentation"
                  alt=""
                  className="hidden lg:inline-block ml-4 lg:ml-8 xl:ml-10 -mt-14"
                />
              ) : null}

              <Link
                className="block w-auto md:w-8/12 text-white hover:text-white"
                to="/signup/"
              >
                <button
                  className={`${props.btnColor} text-white font-bold py-2 px-6 rounded w-auto md:w-auto`}
                >
                  Sign Up
                </button>
              </Link>
            </div>
            <div
              style={{
                background: `url(${chpBg}), backgroundRepeat: "no-repeat",
                  backgroundPosition: "center top",`,
              }}
            ></div>
            <div className="w-auto md:w-1/2 bg-white rounded-r-xl">
              <div className="p-10">
                <ul className="m-0 p-0">
                  {props.featuresArr.map((i) => {
                    return (
                      <li
                        key={Math.random()}
                        className="flex flex-row items-start pl-0 pr-4 py-2 pb-4 md:pb-0"
                      >
                        <img
                          src={customBullet}
                          role="presentation"
                          alt=""
                          className="pt-1 pr-4"
                          style={{ filter: "grayscale(1)", opacity: "0.7" }}
                        />
                        <p className="inline-block text-sm">{i}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PriceBlock;
