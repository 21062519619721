import React from "react";
import Layout from "../../components/Layout";
import Helmet from "react-helmet";
import BottomCTA from "../../components/homepage/BottomCTA";

import bgImage from "../../img/homepage/top-section-bg-chp.png";
import PriceBlock from "../../components/PriceBlock";

const title = "Pricing | Amos Institute";
const description =
  "The Amos Institute's programs and services for cognitive health improvement are the most comprehensive and affordable way to introduce the proven benefits of the Bredesen Protocol into your life.";

const chpFeatures = [
  "25+ high quality lessons containing evidence based strategies with research and references designed for your success on the Bredesen Protocol.",
  'Access to weekly "Office Hours" where you can get advice from our highly trained registered dietitians as you go through your program.',
  "The Amos Institute Ketoflex Nutrition Plan Guide, including a 7-day meal plan, recipes & grocery shopping list.",
  "A trove of curated tools and resources including recommended products from the Amos Institute team.",
  "Monthly self-evaluations to keep you on track with all the healthy changes you will make.",
  "An easy-to-use online interface which provides access to all aspects of your program and our support materials in a single place.",
];

const privateAppointmentFeatures = [
  "Answers the “how” to fully and properly implement the program into your life while keeping in mind your unique lifestyle.",
  "Ideal for motivated individuals who want to yield optimal results, who have specific questions about the nutrition plan, and who wish to have an expert RDN go through their routine to ensure they have fully implemented the program.",
  "Discuss your questions about the Cognitive Health Program (CHP) to help you increase your compliance and success.",
  "Our RDN will help you increase your self-awareness of habits and strengths to help you overcome challenges that have come up.",
  "After the appointment you will receive a summary of topics discussed to help keep you focused and successful on your program.",
];

const labAnalysisFeatures = [
  "Ideal for motivated individuals who want to get the most out of the Cognitive Health Program by having your program customized to meet their unique needs.",
  "With this important service our RDNs will review all of your nutrition related lab work (including any previously obtained ReCODE reports).",
  "Our RDNs will discuss with you ways to personalize the nutrition and lifestyle plan to meet your specific needs based on your biochemical and genetic data.",
  "Our RDNs will make recommendations and provide guidance on supplements that may support your cognitive health and improve your metabolic function, including types of supplements, dosage, and sourcing.",
  "After the appointment, you will receive a summary of the interventions recommended so you will have a clear understanding of the supplements you need and how they are helping you to improve your cognition.",
  "This service can and should be repeated following any new lab work or ReCODE reports, especially for those experiencing ongoing cognitive decline.",
];

const personalizedMealPlanFeatures = [
  "Ideal for individuals who struggle coming up with meals that are compliant with the Ketoflex Nutrition Plan or who are constantly asking themselves “what can I eat?”",
  "Includes a 20-minute online appointment with our RDN to review with you your food preferences, any food allergies you may have, and your current dietary routine.",
  "Our RDN will assess your calorie needs, hydration requirements, and macronutrient ratios based on information gathered during your appointment.",
  "After the appointment, you will receive a 1 Week Meal Plan, which includes 7 days of breakfasts, lunches, dinners, and snacks (when applicable) catered to your specific nutritional needs.",
  "You will receive a Shopping List which will include all ingredients needed to make all of the meals included in the personalized Meal Plan.",
  "Beneficial for individuals who struggle with identifying proper portion sizes or who would like to ensure that they are properly meeting their nutritional and hydration needs.",
];

export default class Index extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet title={title}>
          <meta property="og:title" content={title} />
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
        </Helmet>
        <div
          style={{
            background: `url(${bgImage})`,
            backgroundSize: "1440px auto",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center top",
          }}
        >
          <section className="container mx-auto pb-4 pt-6">
            <div className="w-full text-center">
              <div className="py-4 lg:py-10 px-6 lg:px-0 w-auto lg:w-10/12 mx-auto">
                <h1 className="text-3xl md:text-6xl px-0 lg:px-14 xl:px-18 max-w-screen-sm font-black pb-10 mx-auto">
                  Pricing
                </h1>
                <p className="w-auto lg:w-9/12 pb-10 text-lg text-gray-700 block mx-auto xl:px-4 xl:w-9/12">
                  The most affordable and comprehensive services available,
                  designed to help you achieve your best brain health from
                  anywhere in the world.
                </p>
              </div>
            </div>
          </section>
          <section className="mb-20">
            <div className="mx-auto max-w-screen-lg p-4">
              <PriceBlock
                bgColor="bg-secondary-dark"
                bgImage="chp"
                textColor="text-white"
                btnColor="bg-primary hover:bg-purple-500"
                heading="The Cognitive Health Program"
                featuresArr={chpFeatures}
                description="Our flagship program is designed to help you fight Dementia,
                reverse Alzheimer's Disease and maintain your very best
                cognitive healthspan."
                img={true}
                price="$365"
                priceType="One-Time Payment"
              />
            </div>
          </section>
          <section
            className="py-20 mb-20"
            style={{ backgroundColor: "#F3F4F7" }}
          >
            <div className="uppercase font-bold text-2xl text-gray-400 tracking-widest text-center mb-14">
              Additional Comprehensive Services
            </div>
            <div className="mx-auto max-w-screen-lg p-4">
              <PriceBlock
                bgColor="bg-white"
                bgImage="orange"
                textColor="text-gray-700"
                btnColor="bg-red-600 hover:bg-red-500"
                heading="Private Dietitian Appointment"
                featuresArr={privateAppointmentFeatures}
                description="A 60-minute appointment with an expert RDN to review and discuss any questions about the Cognitive Health Program."
                img={false}
                price="$350"
                priceType=""
              />
              <div className="mb-20"></div>
              <PriceBlock
                bgColor="bg-white"
                bgImage="blue"
                textColor="text-gray-700"
                btnColor="bg-blue-600 hover:bg-blue-500"
                heading="Lab Analysis and Supplement Guidance"
                featuresArr={labAnalysisFeatures}
                description="A 60-minute appointment with a RDN to personalize the nutrition aspects of your program based of your biochemical & genetic data."
                img={false}
                price="$450"
                priceType=""
              />
              <div className="mb-20"></div>
              <PriceBlock
                bgColor="bg-white"
                bgImage="purple"
                textColor="text-gray-700"
                btnColor="bg-purple-600 hover:bg-purple-500"
                heading="Personalized 1-Week Meal Plan"
                featuresArr={personalizedMealPlanFeatures}
                description="A 7-Day meal plan created based on your caloric requirements, macronutrient needs and food preferences."
                img={false}
                price="$475"
                priceType=""
              />
            </div>
          </section>
        </div>
        <BottomCTA />
      </Layout>
    );
  }
}
